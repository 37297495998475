export const mixins = {
    data() {
        return {
            // 1 ต่ำ, 2 ปานกลาง,  3 ดี, 4 ดีมาก
            grade: {
                1: { text: 'มีปัญหาในการทำงาน', color: 'red' },
                2: { text: 'สามารถทำงานได้', color: 'green' },
                3: { text: 'สามารถทำงานได้ดี', color: 'purple' },
                5: { text: 'WAI LOW TASK LOW', color: 'red' },
                6: { text: 'WAI LOW TASK HIGH', color: 'orange' },
                7: { text: 'WAI HIGH TASK LOW', color: 'green' },
                8: { text: 'WAI HIGH TASK HIGH', color: 'purple' },
                9: { text: 'ทำงานได้ต่ำกว่าเกณฑ์', color: 'red' },
                10: { text: 'ทำงานได้สูงกว่าเกณฑ์', color: 'green' },
            },
            //selectedAlgo: null,
            selectedLoop: 50,
            isLoading: false,
            isModal: false,
            message: null,
            score: null,
        }
    },
    computed: {
        words() {
            return this.$store.getters['word/getWords']
        }
    },
    methods: {
        print() {
            window.print()
        },
        downloadFile(fileName) {
            const { VUE_APP_ML_ENDPOINT } = process.env
            window.open(
                `${VUE_APP_ML_ENDPOINT}/download/template?name=${fileName}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        }
    }
}