<template>
  <!-- <button id="btn-move-top" type="button" @click="moveToTop">
    {{ label }}
  </button>-->
  <Button
    id="btn-move-top"
    :label="label"
    @click="moveToTop"
    icon="pi pi-angle-double-up"
  />
</template>
<script>
export default {
  name: 'moveToTopPage',
  props: {
    label: {
      type: String,
      default: () => 'กลับขึ้นด้านบน'
    }
  },
  mounted() {
    //Get the button:
    const button = document.getElementById('btn-move-top')

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = () => {
      this.scrollbarMove(button)
    }
  },
  methods: {
    scrollbarMove(mybutton) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = 'block'
      } else {
        mybutton.style.display = 'none'
      }
    },
    moveToTop() {
      //document.body.scrollTop = 0 // For Safari
      //document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
      window.scroll({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
#btn-move-top {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: #01346f; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 8px; /* Some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
}

#btn-move-top:hover {
  background-color: #0260ad; /* Add a dark-grey background on hover */
}
</style>