<template>
  <Dialog v-model:visible="isOpen" :closable="false" :style="{ width: '50vw' }">
    <template #header>
      <h3>กราฟแสดงระดับของปัญหาสุขภาพกับความสามารถในการทำงาน</h3>
    </template>

    <p>1) มีอาการปวดคอมากจนร้าวลงแขน</p>
    <p>2) มีอาการปวดหลังมากจนร้าวลงขา</p>
    <p>3) มีปัญหากล้ามเนื้อหรือเส้นเอ็นที่แขนหรือขาที่ทำให้เกิดอาการปวดบ่อย</p>
    <p>
      4) มีปัญหาข้อต่อที่ทำให้มีอาการปวดหรือเคลื่อนไหวได้ไม่เต็มที่ เช่น
      ข้อไหล่ติด ข้อเข่าเสื่อม ข้อต่อผิดรูป ยกแขนได้ไม่สุดแขน ขาโก่ง เป็นต้น
    </p>
    <p>5) ไม่สามารถนั่งนานเกิน 30 นาที</p>
    <p>6) ไม่สามารถยืน-เดินนานเกิน 30 นาที</p>
    <p>7) ไม่สามารถก้มหรือเอี้ยวตัวได้เต็มที่</p>
    <p>8) ไม่สามารถนั่งยองๆ หรือมีปัญหาขณะลุกขึ้นยืนจากท่านั่งยอง</p>
    <p>
      9) ไม่สามารถนั่งบนพื้นได้นาน เช่น นั่งคุกเข่า นั่งขัดสมาธิ
      นั่งพับเพียบหรือมีปัญหาขณะลุกขึ้นยืนจาก ท่านั่งบนพื้น
    </p>
    <p>10) ไม่สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้</p>
    <p>11) ไม่สามารถยกของขึ้น-ลง ผลักหรือดึงของ ซ้ำๆบ่อยๆ ได้</p>
    <p>12) มีปัญหาในการหยิบจับ วัตถุขนาดเล็ก เช่น เหรียญ</p>
    <p>13) ไม่สามารถคิด หรือตัดสินใจ ในปัญหาที่เกิดขึ้นได้ทันที</p>
    <p>14) มีปัญหาการได้ยินเสียงไม่ชัดเจน</p>
    <p>
      15) มีอาการชา ซู่ซ่าเหมือนเป็นเหน็บ หรือแปลบปลาบคล้ายเข็มตำ
      ที่บริเวณใดบริเวณหนึ่งของแขน ขา มือ หรือ เท้า
    </p>
    <p>16) มีปัญหาผิวหนังแพ้ง่าย</p>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => $emit('dimmer')"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalHealthSum',
  props: ['is-open']
}
</script>

<style>
</style>