<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      วิเคราะห์ภาวะสุขภาพ และโรคเรื้อรัง
    </template>
    <div>
      การวิเคราะห์ข้อมูลภาวะสุขภาพ
      และโรคเรื้อรังเป็นการวิเคราะห์ในรายละเอียดของภาวะสุขภาพผู้สูงอายุ
      และโรคเรื้อรังที่ผู้สูงเป็นอยู่ เทียบกับศักยภาพในการทำงาน
      โดยการวิเคราะห์ในส่วนนี้ จะเป็นการนำเสนอ
    </div>
    <div>
      1.ข้อมูลระดับของปัญหาสุขภาพกับความสามารถในการทำงาน
      (แยกพิจารณาตามจำนวนผู้สูงอายุ, ช่วงอายุผู้สูงอายุ)
    </div>
    <div>2.ข้อมูลจำนวนผู้สูงอายุที่ป่วยเป็นโรคต่างๆ</div>
    <div>
      3.ข้อมูลจำนวนผู้สูงอายุที่เป็นโรคความดันโลหิตสูงเทียบกับศักยภาพการทำงาน
    </div>
    <div>4.ข้อมูลจำนวนผู้สูงอายุที่เป็นโรคเบาหวานเทียบกับศักยภาพการทำงาน</div>
  </Fieldset>
  <Fieldset :legend="images['section4_score']">
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1 p-fluid">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => (isModal = true)"
        />
      </div>
    </div>
    <img :src="plots['section4_score']" :style="graphStyle" />
    <DividerWord :content="words['HEALTH01']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุแยกตามระดับปัญหาสุขภาพและตามช่วงอายุ"
  >
    <div class="p-grid p-jc-end p-fluid">
      <div class="p-col-1">
        <Button
          icon="pi pi-info-circle"
          class="p-button-primary"
          @click="() => (isModal = true)"
        />
      </div>
    </div>
    <div ref="chartStackHealthSum" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH02']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงค่าเฉลี่ยของปัญหาสุขภาพระหว่างช่วงอายุแยกตามเพศ">
    <div ref="chartClusterHealthSum" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH03']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุที่ป่วยเป็นโรคต่างๆ">
    <div ref="chartPieDisease" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH04']" />
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุที่เป็นโรคเรื้อรังแยกตามช่วงอายุ">
    <div ref="chartClusterAgeRange" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH05']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุที่เป็นโรคความดันโลหิตสูงแยกตามช่วงอายุและสถานะการทำงาน"
  >
    <div ref="chartClusterCareerHighBlood" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH06']" />
  </Fieldset>
  <Fieldset
    legend="กราฟแสดงจำนวนผู้สูงอายุที่เป็นโรคเบาหวานแยกตามช่วงอายุและสถานะการทำงาน"
  >
    <div ref="chartClusterCareerDiabetes" :style="graphStyle"></div>
    <DividerWord :content="words['HEALTH07']" />
  </Fieldset>
  <ModalHealthSum :is-open="isModal" @dimmer="() => (isModal = false)" />
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import ModalHealthSum from './ModalHealthSum'
import DividerWord from './DividerWord'
// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      series: {
        healthSumAgeRange: null,
        healthSumGender: null,
        ageRangeDiseaseTotal: null,
        disease: null,
        careerDisease1: null,
        careerDisease2: null
      },
      plots: {
        section4_score: null
      },
      images: {
        section4_score: 'กราฟแสดงระดับของปัญหาสุขภาพกับความสามารถในการทำงาน'
      },
      isModal: false
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const {
          healthSumAgeRange,
          healthSumGender,
          ageRangeDiseaseTotal,
          disease,
          careerDisease1,
          careerDisease2
        } = newVal
        this.stackColumnChart(healthSumAgeRange)
        this.clusterColumnHealthSum(healthSumGender)
        this.clusterColumnAgeRange(ageRangeDiseaseTotal)
        this.pieDisease(disease)
        this.clusterColumnCareerHighBlood(careerDisease1)
        this.clusterColumnCareerDiabetes(careerDisease2)
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'HEALTH',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data
      this.getGraphBarplot()
      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    },
    stackColumnChart(healthSumAgeRange) {
      // Create chart instance
      const chart = am4core.create(
        this.$refs.chartStackHealthSum,
        am4charts.XYChart
      )

      // Add data
      chart.data = [
        {
          category: '0',
          value1: healthSumAgeRange?.count001,
          value2: healthSumAgeRange?.count002,
          value3: healthSumAgeRange?.count003
        },
        {
          category: '1',
          value1: healthSumAgeRange?.count011,
          value2: healthSumAgeRange?.count012,
          value3: healthSumAgeRange?.count013
        },
        {
          category: '2',
          value1: healthSumAgeRange?.count021,
          value2: healthSumAgeRange?.count022,
          value3: healthSumAgeRange?.count023
        },
        {
          category: '3',
          value1: healthSumAgeRange?.count031,
          value2: healthSumAgeRange?.count032,
          value3: healthSumAgeRange?.count033
        },
        {
          category: '4',
          value1: healthSumAgeRange?.count041,
          value2: healthSumAgeRange?.count042,
          value3: healthSumAgeRange?.count043
        },
        {
          category: '5',
          value1: healthSumAgeRange?.count051,
          value2: healthSumAgeRange?.count052,
          value3: healthSumAgeRange?.count053
        },
        {
          category: '6',
          value1: healthSumAgeRange?.count061,
          value2: healthSumAgeRange?.count062,
          value3: healthSumAgeRange?.count063
        },
        {
          category: '7',
          value1: healthSumAgeRange?.count071,
          value2: healthSumAgeRange?.count072,
          value3: healthSumAgeRange?.count073
        },
        {
          category: '8',
          value1: healthSumAgeRange?.count081,
          value2: healthSumAgeRange?.count082,
          value3: healthSumAgeRange?.count083
        },
        {
          category: '9',
          value1: healthSumAgeRange?.count091,
          value2: healthSumAgeRange?.count092,
          value3: healthSumAgeRange?.count093
        },
        {
          category: '10',
          value1: healthSumAgeRange?.count101,
          value2: healthSumAgeRange?.count102,
          value3: healthSumAgeRange?.count103
        },
        {
          category: '11',
          value1: healthSumAgeRange?.count111,
          value2: healthSumAgeRange?.count112,
          value3: healthSumAgeRange?.count113
        },
        {
          category: '12',
          value1: healthSumAgeRange?.count121,
          value2: healthSumAgeRange?.count122,
          value3: healthSumAgeRange?.count123
        },
        {
          category: '13',
          value1: healthSumAgeRange?.count131,
          value2: healthSumAgeRange?.count132,
          value3: healthSumAgeRange?.count133
        },
        {
          category: '14',
          value1: healthSumAgeRange?.count141,
          value2: healthSumAgeRange?.count142,
          value3: healthSumAgeRange?.count143
        },
        {
          category: '15',
          value1: healthSumAgeRange?.count151,
          value2: healthSumAgeRange?.count152,
          value3: healthSumAgeRange?.count153
        },
        {
          category: '16',
          value1: healthSumAgeRange?.count161,
          value2: healthSumAgeRange?.count162,
          value3: healthSumAgeRange?.count163
        }
      ]

      chart.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.strictMinMax = true
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.title.text = 'จำนวนปัญหาสุขภาพ'

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      //valueAxis.renderer.inside = true
      //valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0
      valueAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      //valueAxis.renderer.minGridDistance = 20

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.name = name
        series.dataFields.valueY = field
        series.dataFields.categoryX = 'category'
        series.sequencedInterpolation = true

        // Make it stacked
        series.stacked = true

        // Configure columns
        series.columns.template.width = am4core.percent(70)
        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.text = '{valueY}'
        labelBullet.locationY = 0.5
        labelBullet.label.hideOversized = true

        return series
      }
      createSeries('value1', '60 - 65')
      createSeries('value2', '66 - 70')
      createSeries('value3', '> 70')

      // Legend
      chart.legend = new am4charts.Legend()
      //chart.legend.labels.template.text = 'อายุ: [bold {color}]{name}[/]'
      chart.legend.labels.template.text = 'อายุ {name}[/] ปี'
    },
    clusterColumnHealthSum(healthSumGender) {
      var chart = am4core.create(
        this.$refs.chartClusterHealthSum,
        am4charts.XYChart
      )
      chart.colors.list = [am4core.color('#54ccfc'), am4core.color('#f7a0d5')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'ค่าเฉลี่ยปัญหาสุขภาพ'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      chart.data = [
        {
          category: '60 - 65 ปี',
          first: healthSumGender?.count12,
          second: healthSumGender?.count11
        },
        {
          category: '66 - 70 ปี',
          first: healthSumGender?.count22,
          second: healthSumGender?.count21
        },
        {
          category: '> 70 ปี',
          first: healthSumGender?.count32,
          second: healthSumGender?.count31
        }
      ]

      createSeries('first', 'ชาย')
      createSeries('second', 'หญิง')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },
    clusterColumnAgeRange(ageRangeDiseaseTotal) {
      const chart = am4core.create(
        this.$refs.chartClusterAgeRange,
        am4charts.XYChart
      )
      chart.colors.step = 3

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95
      chart.legend.labels.template.text = 'จำนวนโรค {name}[/] โรค'

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: ageRangeDiseaseTotal?.count00,
          second: ageRangeDiseaseTotal?.count01,
          third: ageRangeDiseaseTotal?.count02,
          four: ageRangeDiseaseTotal?.count03,
          five: ageRangeDiseaseTotal?.count04,
          six: ageRangeDiseaseTotal?.count05,
          seven: ageRangeDiseaseTotal?.count06
        },
        {
          category: '66 - 70  ปี',
          first: ageRangeDiseaseTotal?.count10,
          second: ageRangeDiseaseTotal?.count11,
          third: ageRangeDiseaseTotal?.count12,
          four: ageRangeDiseaseTotal?.count13,
          five: ageRangeDiseaseTotal?.count14,
          six: ageRangeDiseaseTotal?.count15,
          seven: ageRangeDiseaseTotal?.count16
        },
        {
          category: '> 70  ปี',
          first: ageRangeDiseaseTotal?.count20,
          second: ageRangeDiseaseTotal?.count21,
          third: ageRangeDiseaseTotal?.count22,
          four: ageRangeDiseaseTotal?.count23,
          five: ageRangeDiseaseTotal?.count24,
          six: ageRangeDiseaseTotal?.count25,
          seven: ageRangeDiseaseTotal?.count26
        }
      ]

      createSeries('first', '0')
      createSeries('second', '1')
      createSeries('third', '2')
      createSeries('four', '3')
      createSeries('five', '4')
      createSeries('six', '5')
      createSeries('seven', '6')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },

    pieDisease(disease) {
      // กราฟแสดงจำนวนผู้สูงอายุที่ป่วยเป็นโรคต่างๆ

      // Create chart instance
      var chart = am4core.create(this.$refs.chartPieDisease, am4charts.PieChart)

      chart.legend = new am4charts.Legend()
      chart.legend.valueLabels.template.disabled = true

      // Add data
      chart.data = [
        {
          country: 'ความดันโลหิตสูง',
          litres: disease?.sum01
        },
        {
          country: 'เบาหวาน',
          litres: disease?.sum02
        },
        {
          country: 'โรคหัวใจ',
          litres: disease?.sum03
        },
        {
          country: 'โรคหลอดเลือดสมอง',
          litres: disease?.sum04
        },
        {
          country: 'โรคปอด/หอบหืด',
          litres: disease?.sum05
        },
        {
          country: 'โรคมะเร็ง',
          litres: disease?.sum06
        },
        {
          country: 'มีอาการทางจิตเวช',
          litres: disease?.sum07
        },
        {
          country: 'โรคอ้วน',
          litres: disease?.sum08
        },
        {
          country: 'โรคเก๊าท์/ข้อเสื่อม/กระดูกเสื่อม',
          litres: disease?.sum09
        },
        {
          country: 'ความจำเสื่อม/สมองเสื่อม',
          litres: disease?.sum10
        },
        {
          country: 'ซึมเศร้า',
          litres: disease?.sum11
        },
        {
          country: 'อื่นๆ',
          litres: disease?.sum12
        }
      ]

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries())
      pieSeries.colors.step = 2
      pieSeries.dataFields.value = 'litres'
      pieSeries.dataFields.category = 'country'
      pieSeries.slices.template.stroke = am4core.color('#fff')
      pieSeries.slices.template.strokeOpacity = 1

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1
      pieSeries.hiddenState.properties.endAngle = -90
      pieSeries.hiddenState.properties.startAngle = -90

      chart.hiddenState.properties.radius = am4core.percent(0)
    },

    clusterColumnCareerHighBlood(careerDisease1) {
      const chart = am4core.create(
        this.$refs.chartClusterCareerHighBlood,
        am4charts.XYChart
      )
      chart.colors.list = [am4core.color('#a6d0cc'), am4core.color('#f28ba0')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: careerDisease1?.count11,
          second: careerDisease1?.count12
        },
        {
          category: '66 - 70  ปี',
          first: careerDisease1?.count21,
          second: careerDisease1?.count22
        },
        {
          category: '> 70  ปี',
          first: careerDisease1?.count31,
          second: careerDisease1?.count32
        }
      ]

      createSeries('first', 'ประกอบอาชีพ')
      createSeries('second', 'ไม่ได้ประกอบอาชีพ')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },

    clusterColumnCareerDiabetes(careerDisease2) {
      // โรคเบาหวาน

      const chart = am4core.create(
        this.$refs.chartClusterCareerDiabetes,
        am4charts.XYChart
      )
      chart.colors.list = [am4core.color('#a6d0cc'), am4core.color('#f28ba0')]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: careerDisease2?.count11,
          second: careerDisease2?.count12
        },
        {
          category: '66 - 70  ปี',
          first: careerDisease2?.count21,
          second: careerDisease2?.count22
        },
        {
          category: '> 70  ปี',
          first: careerDisease2?.count31,
          second: careerDisease2?.count32
        }
      ]

      createSeries('first', 'ประกอบอาชีพ')
      createSeries('second', 'ไม่ได้ประกอบอาชีพ')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    }
  },
  components: {
    ModalHealthSum,
    MoveToTopPage,
    DividerWord
  }
}
</script>

<style>
</style>