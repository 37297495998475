<template>
  <div>
    <Divider align="left">
      <div class="p-d-inline-flex p-ai-center">
        <i class="pi pi-question p-mr-1"></i>
        <b>{{ title }}</b>
      </div>
    </Divider>
    <p v-html="word"></p>
  </div>
</template>

<script>
export default {
  name: 'dividerWord',
  props: {
    title: {
      type: String,
      default: () => 'คำอธิบายเพิ่มเติม'
    },
    content: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    word() {
      if (this.content) {
        return `&nbsp;&nbsp;&nbsp;&nbsp; ${this.content?.graphWord}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style>
</style>